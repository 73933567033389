
export default {
	name: 'projectParticulars',
	data() {
		return {
			sex: '',
			newEdit: false,
			projectName: '', //标题
			shortName: '', //项目简称
			crcUserList: '', //列表
			departmentName: '', //科室
			diseaseName: '', //适应症
			startTime: '',
			endTime: '',
			removeAlert: false,
			outId: "",
			addinterviewAlert: false, //添加访视弹框控制
			alertTitle: '添加访视',
			options: '',
			interview: [], //前置访视
			interviewClassList: [{
				name: '筛选期', value: '1'
			}, {
				name: '试验期', value: '2'
			}, {
				name: '计划外访视患者无关', value: '3'
			}],//访视类型列表
			work: {
				workName: '', //访视名称
				workCycle: '', //访视周期
				complex: '', //访视类型
				frontWorkName: '', //前置访视
				id: '',
				windowNum: '', //窗口期
			},
			dailyStatistics: [],
			checkList: [], //检查项列表
			cardId: '', //访视id
			crcNameList: [],
			crcName: '',
			subjectsList: [],
			crcWorkList: [],
			tabelTitleList: [],//CRC工作量统计表格title
			crcWorkDateStart: new Date(), //CRC工作量统计开始时间
			crcWorkDateEnd: new Date(), //CRC工作量统计结束时间
		}
	},
	created() {
		this.token = this.getCookie('token')
		this.type = this.getCookie('type')
		this.getCrcWorkDateEnd()
		this.getTabel1(this.crcName)
		this.getTabel2(this.crcWorkDateStart, this.crcWorkDateEnd)
		this.getProPar()
		this.getCrcNameList()
	},
	mounted() {
		this.drawLine();
	},

	methods: {
		//获取系统前一个月的时间
		getCrcWorkDateEnd() {
			this.crcWorkDateStart.setMonth(this.crcWorkDateStart.getMonth() - 1);
			var y = this.crcWorkDateStart.getFullYear();
			var m = this.crcWorkDateStart.getMonth() + 1;
			var d = this.crcWorkDateStart.getDate();
			this.crcWorkDateStart = y + '-' + m + '-' + d;
		},
		//echatrs
		drawLine() {
			var _this = this;
			// 基于准备好的dom，初始化echarts实例
			// 绘制图表
			_this.getEchar1Data()
			// 绘制图表
			_this.getEchar2Data()
		},
		getEchar1Data() {
			var _this = this;
			_this.$http.post('/API/PLAT/findPatientJDFB', {
				projectId: _this.$route.params.id
			}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (res) {
					if (res.data.code == 200) {
						_this.getEchar1(res.data.data.typeArray)
					}
				})
		},
		getEchar2Data() {
			var _this = this;
			_this.$http.post('/API/PLAT/patientJoinANDQuit', {
				projectId: _this.$route.params.id
			}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (res) {
					if (res.data.code == 200) {
						_this.getEchar2(res.data.data)
					}
				})
		},
		getEchar1(data) {
			let echar1 = this.$echarts.init(document.getElementById('echar-1'))
			echar1.setOption({
				tooltip: {
					trigger: 'item',
					formatter: "{a} <br/>{b} : {c} ({d}%)"
				},
				series: [{
					name: '访问来源',
					type: 'pie',
					radius: '55%',
					center: ['50%', '60%'],
					data: data,
					itemStyle: {
						emphasis: {
							shadowBlur: 10,
							shadowOffsetX: 0,
							shadowColor: 'rgba(0, 0, 0, 0.5)'
						},
						normal: {
							color: function (params) {
								//自定义颜色
								var colorList = [
									'#43B3EC', '#6DE0E2', '#FEDA66', '#FD9F82'
								];
								return colorList[params.dataIndex]
							}
						}
					}
				}]
			})
		},
		getEchar2(data) {
			var that = this
			let echar2 = this.$echarts.init(document.getElementById('echar-2'))
			echar2.setOption({
				tooltip: {
					trigger: 'axis',
					axisPointer: { // 坐标轴指示器，坐标轴触发有效
						type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
					}
				},
				legend: {
					data: ['脱落数', '入组数']
				},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: {
					type: 'category',
					data: data.x,
					axisLine: {
						lineStyle: {
							color: '##CBD1DA'
						}
					}
				},
				yAxis: {
					type: 'value',
					min: 0,
					max: 100,
					interval: 20,
					boundaryGap: [0, 0.01],
					axisLine: {
						lineStyle: {
							color: '##CBD1DA'
						}
					}
				},
				series: [{
					name: '脱落数',
					type: 'bar',
					barWidth: '25%',
					color: '#61C7D7',
					label: {
						normal: {
							show: true,
							position: 'insideRight'
						}
					},
					data: data.y2
				},
				{
					name: '入组数',
					type: 'bar',
					barWidth: '25%',
					color: '#FFE188',
					label: {
						normal: {
							show: true,
							position: 'insideRight'
						}
					},
					data: data.y1
				}
				]
			});
		},

		subjectsChange(id) {
			this.getTabel1(id)
		},
		toSickParticulars(e) {
			this.$router.push({
				name: 'sickParticulars',
				params: {
					id: this.$route.params.id,
					patientId: e.patientId
				}
			})
		},
		toSickList() {
			this.$router.push({ name: 'sickList', params: { id: this.$route.params.id } })
		},
		getProPar() {
			var _this = this;
			_this.$http.post('/API/PLAT/findProjectEntity', {
				projectId: _this.$route.params.id
			}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					if (response.data.code == 200) {
						//console.log(response.data.data.crcUserList)
						//						_this.crcUserList = response.data.data.crcUserList;
						_this.projectName = response.data.data.projectName;
						_this.departmentName = response.data.data.departmentIds;
						_this.diseaseName = response.data.data.symptomName;
						_this.shortName = response.data.data.shortName;
						_this.startTime = new Date(response.data.data.startTime);
						_this.startTime = _this.formatDate(_this.startTime)
					}
				})
		},
		getCrcNameList() {
			var _this = this;
			_this.$http.post('API/PLAT/findCrcByProject', {
				projectId: _this.$route.params.id
			}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					if (response.data.code == 200) {
						_this.crcNameList = response.data.data.crcArray;
						_this.crcName = response.data.data.crcArray[0].crcId;
					}
				})
		},
		getTabel1(name) { //受试者进度统计
			var _this = this;
			_this.$http.post('API/PLAT/patientPlanStatistics', {
				projectId: _this.$route.params.id,
				crcId: name
			}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					if (response.data.code == 200) {
						_this.subjectsList = response.data.data.patientArray;
					}
				})
		},
		getTabel2(crcWorkDateStart, crcWorkDateEnd) { //受试者进度统计
			var _this = this;
			_this.$http.post('API/PLAT/crcJobStatistics', {
				projectId: _this.$route.params.id,
				startTime: new Date(crcWorkDateStart),
				endTime: new Date(crcWorkDateEnd)
			}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					if (response.data.code == 200) {
						_this.tabelTitleList = response.data.data.listMap[0];
						_this.crcWorkList = response.data.data.listMap;
						_this.crcWorkList.splice(0, 1)
					}
				})
		},
		tableRowClassName({
			row,
			rowIndex
		}) {
			if (rowIndex === 1) {
				return "warning-row";
			} else if (rowIndex === 3) {
				return "success-row";
			}
			return "";
		},
		outProject(id) {
			this.outId = id;
			this.removeAlert = true;
		},
		dateChange(index, time) {
			var _this = this;
			if (_this.work.startTime > time) {
				time = _this.work.startTime
			}
		},
		deleteCheck(item, index) { //删除检查项
			if (this.cardId == "") {
				this.checkList.splice(index, 1)
			} else {
				this.$confirm('确认删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					item.isbase = '2';
					this.$message({
						type: 'success',
						message: '删除成功!'
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			}
		},
		yes() {
			this.removeAlert = false;
			var _this = this;
			_this.$http.post('/API/PLAT/outProject', {
				pid: _this.$route.params.id,
				crcUserId: _this.outId
			}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					if (response.data.code == 200) {
						//						console.log(response)
						_this.getProPar()
					}
				})
		},
		no() {
			this.removeAlert = false;
		},
		//跳转CRC详情
		toPar(id) {
			this.$router.push({
				name: 'crcParticulars',
				params: {
					id: id
				}
			});
		},
		toPl() {
			this.$router.go(-1)
		},
		toinPart(id) { //跳转访视详情
			this.$router.push({
				name: 'interviewParticulars',
				params: {
					id: id
				}
			});
			//				this.$router.push('/interviewParticulars')
		},
		toSubjects(item) {
			var _this = this;
			_this.setCookie('crcId', item.crcId);//crc名字
			this.$router.push({
				name: 'subjects',
				params: {
					patientId: item.patientId,
					projectId: _this.$route.params.id
				}
			});
		},
		formatDate(date) {
			var y = date.getFullYear();
			var m = date.getMonth() + 1;
			m = m < 10 ? ('0' + m) : m;
			var d = date.getDate();
			d = d < 10 ? ('0' + d) : d;
			var h = date.getHours();
			var minute = date.getMinutes();
			minute = minute < 10 ? ('0' + minute) : minute;
			var second = date.getSeconds();
			second = minute < 10 ? ('0' + second) : second;
			return y + '-' + m + '-' + d;
		},
	},
}
